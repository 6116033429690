import React from 'react';

import Button from 'src/components/Button';

interface Props {
  canPreviousStep: boolean;
  onPreviousStep: () => void;
  onNextStep: () => void;
  disableNextStep: boolean;
  nextLabel?: string;
}

const AssessmentFormButtons: React.FC<Props> = ({
  canPreviousStep,
  disableNextStep,
  onNextStep,
  onPreviousStep,
  nextLabel = 'Next',
}) => {
  return (
    <div className='flex justify-center gap-4 p-4'>
      {canPreviousStep && (
        <Button
          buttonStyle='light'
          buttonType='outline'
          onClick={onPreviousStep}
        >
          Previous
        </Button>
      )}
      <Button
        buttonStyle='accent-1'
        disabled={disableNextStep}
        onClick={disableNextStep ? undefined : onNextStep}
      >
        {nextLabel || 'Next'}
      </Button>
    </div>
  );
};

export default AssessmentFormButtons;
