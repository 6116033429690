import { createContext } from 'react';

export interface AuthContextValue {
  handleLogout: () => void;
  handleLoginWithRedirect: () => void;
  isAuthenticated: boolean;
}

const AuthContext = createContext<AuthContextValue>({} as AuthContextValue);

export default AuthContext;
