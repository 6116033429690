import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import AssessmentForm from './components/AssessmentForm';
import AssessmentIntroduction from './components/AssessmentIntroduction';
import { AssessmentStatus } from 'src/api';
import { useAssessmentContext } from 'src/contexts/AssessmentContext';

const Assessment = () => {
  const {
    details: { template, assessmentStatus },
    termsOfService,
  } = useAssessmentContext();
  const navigate = useNavigate();

  const [started, setStarted] = useState(
    !template.template_details.display_carousel
  );

  useEffect(() => {
    if (!termsOfService?.acceptedAt) {
      navigate('/terms');
    }
  }, [navigate, termsOfService?.acceptedAt]);

  useEffect(() => {
    if (assessmentStatus === AssessmentStatus.COMPLETE) {
      navigate('/assessment-complete');
    }
  }, [navigate, assessmentStatus]);

  if (started) return <AssessmentForm />;

  return <AssessmentIntroduction onBegin={() => setStarted(true)} />;
};

export default Assessment;
