import { XMarkIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import * as pdfjs from 'pdfjs-dist';
import pdfWorker from 'pdfjs-dist/build/pdf.worker?url';
import React, { useEffect, useRef, useState } from 'react';

pdfjs.GlobalWorkerOptions.workerSrc = pdfWorker;

export interface ImageThumbnailProps {
  url: string;
  onRemoveFile: () => void;
  disabled?: boolean;
}

const ImageThumbnail: React.FC<ImageThumbnailProps> = ({
  url,
  onRemoveFile,
  disabled,
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [imageFailedLoading, setImageFailedLoading] = useState(false);

  useEffect(() => {
    const loadPdf = async () => {
      const loadingTask = pdfjs.getDocument(url);
      const pdf = await loadingTask.promise;
      const page = await pdf.getPage(1);

      const scale = 0.5; // Adjust this for your desired thumbnail size
      const viewport = page.getViewport({ scale });

      const canvas = canvasRef.current;

      if (canvas) {
        const context = canvas.getContext('2d');

        if (!context) return;

        context.clearRect(0, 0, canvas.width, canvas.height);
        context.beginPath();

        canvas.height = viewport.height;
        canvas.width = viewport.width;

        page.render({
          canvasContext: context,
          viewport,
        });
      }
    };

    if (imageFailedLoading) {
      void loadPdf();
    }
  }, [imageFailedLoading, url]);

  const onError: React.ReactEventHandler<HTMLImageElement> = (event) => {
    event.currentTarget.onerror = null;
    setImageFailedLoading(true);
  };

  return (
    <div className='relative h-14 w-14 pr-2 pt-2'>
      {imageFailedLoading && (
        <canvas className='h-12 w-12 rounded-md' ref={canvasRef}></canvas>
      )}
      {!imageFailedLoading && (
        <img
          src={url}
          onError={onError}
          alt=''
          className={clsx('h-12 w-12 rounded-md object-cover', {
            'grayscale filter': disabled,
          })}
        />
      )}
      <div
        className={clsx(
          'absolute right-0 top-0 h-4 w-4 rounded-full',
          disabled
            ? 'cursor-not-allowed bg-gray-300 text-gray-500'
            : 'cursor-pointer bg-yellow-500 text-gray-900'
        )}
        onClick={onRemoveFile}
      >
        <XMarkIcon />
      </div>
    </div>
  );
};

export default ImageThumbnail;
