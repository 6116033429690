const termsOfService = `
      <ol>
        <li>
          License. Subject to the terms and conditions of this Agreement, Maiven
          grants Customer a non-exclusive, non-transferable, paid-up license
          during the Term (as defined below) to use the Service solely for
          Customer's internal testing and evaluation purposes to determine the
          suitability of the Service and the presence of bugs and other items
          that may detract from the effectiveness of the Service. This license
          terminates on the expiration or termination of this Agreement.
        </li>
        <li>
          Use Restrictions. Customer shall (a) not adapt, alter, modify,
          decompile, disassemble, reverse engineer, translate or create derivate
          works of Maiven's technology used to create the Service; and (b) keep
          confidential and not disclose to any third party or otherwise make
          publicly available any information related to the Service, including,
          but not limited to, test results, characteristics, and performance of
          the Service.
        </li>
        <li>
          Evaluations; Publicity. As consideration for Maiven granting Customer
          a license to use the Service, Customer shall notify Maiven of all
          problems and ideas for enhancements that come to Customer's attention
          while using the Service. Upon Maiven's reasonable request, Customer
          shall provide Maiven written evaluations of the Service in a
          reasonable amount of time following request for such evaluations. Such
          evaluations will be provided in a format that Maiven chooses. Customer
          shall not, without Maiven's prior written consent, publish or disclose
          to any third party that it is evaluating the Service or share any
          feedback, summary, or report regarding the Service. Customer hereby
          grants Maiven the right and license to use Customer's trademarks for
          Maiven's marketing and promotional purposes in order to identify
          Customer as a customer of Maiven. Customer acknowledges and agrees
          that Maiven has set its prices and entered into this Agreement in
          reliance upon the terms of this Section 1.3 and that such terms form
          an essential basis of the bargain between the parties.
        </li>
        <li>
          No Obligations. Neither the provision of the Service, nor this
          Agreement, creates any obligation for Maiven to develop, productize,
          support, repair, offer for sale, provide assistance regarding, or in
          any other way continue to provide or develop the Service to Customer
          or any other entity or person. Customer has no recourse against Maiven
          for any losses related to any modification or termination of the
          Service.
        </li>
        <li>INTELLECTUAL PROPERTY RIGHTS.</li>
        <li>
          Maiven Service and Technology. The Service, and the databases,
          software, hardware, and other technology used by or on behalf of
          Maiven to operate the Service, and the structure, organization, and
          underlying data, information, and software code thereof (collectively,
          the “Technology”), constitute valuable trade secrets of Maiven. Maiven
          retains all right, title, and interest, including, without limitation,
          all intellectual property rights, in and to the Technology and any
          additions, improvements, updates, modifications, and derivatives
          thereto. Customer receives no ownership interest in or to the
          Technology and is not granted any right or license to use the
          Technology itself, apart from its ability to access the Service under
          this Agreement. The Maiven name, logo, and all product and service
          names associated with the Service are trademarks of Maiven and
          Customer is granted no right or license to use them. Any rights not
          expressly granted to Customer hereunder are reserved by Maiven.
        </li>
        <li>
          Customer Data. As between Customer and Maiven, Customer owns all
          right, title, and interest in and to the electronic data and
          information input into the Service by or on behalf of Customer
          (collectively, “Customer Data”). Customer hereby grants to Maiven and
          its authorized representatives and contractors, during the Term, a
          limited, non-exclusive, non-transferable (except as permitted by
          Section 10.4) license to use the Customer Data for the purpose of
          providing the Service for Customer and folfilling its other
          obligations and exercising its rights under this Agreement, including
          for technical support, implementation, operation or administration of
          the Service. Maiven may analyze Customer Data, and data of other
          customers, to create deidentified or aggregated statistics and data
          that do not identify Customer or any individual, household, user,
          browser, or device (“Usage Data”) and Maiven may during and after the
          Term use and disclose such Usage Data in its discretion.
        </li>
        <li>
          Feedback. Notwithstanding the above or anything to the contrary
          herein, to the extent that Customer at any time provides Maiven with
          any feedback or suggestions regarding the Service, including potential
          improvements or changes thereto and any evaluations and reports
          submitted by Customer pursuant to Section 1.3 and intellectual
          property contained in or reasonably extrapolated therefrom
          (collectively, “Feedback”), such Feedback shall not be considered
          Confidential Information (as defined below) of Customer, and Maiven
          may use, disclose and exploit such Feedback in any manner it chooses.
          Maiven is the exclusive owner of the Feedback. Customer waives any
          rights it may have in the Feedback and assigns to Maiven all right,
          title, and interest therein.
        </li>
        <li>
          CONFIDENTIALITY.

          <ol>
            <li>
              Definition. “Confidential Information” means the terms and
              conditions of this Agreement and all information related to a
              party's business, financial affairs, or operations, including but
              not limited to information related to business plans, technology,
              source code, product or service development plans, pricing,
              techniques, and methods, which is either marked or identified as
              confidential or which the Receiving Party knew or reasonably
              shoold have known, under the circumstances, was confidential.
              Without limiting the foregoing, (a) Maiven's Confidential
              Information includes any information regarding the Service
              disclosed or obtained by Customer under this Agreement, its
              Technology, and the Feedback; and (b) Customer's Confidential
              Information includes the Customer Data.
            </li>
            <li>
              Protection. The party receiving Confidential Information
              (“Receiving Party”) from the other party (“Disclosing Party”) will
              not use any Confidential Information of the Disclosing Party for
              any purpose not expressly permitted by this Agreement, and will
              disclose the Confidential Information of the Disclosing Party only
              to the employees or contractors of the Receiving Party who have a
              need to know such Confidential Information for purposes of this
              Agreement and who are under a duty of confidentiality no less
              restrictive than the Receiving Party's duty hereunder. The
              Receiving Party will be liable to the Disclosing Party for any
              breach of this Section 4.2 by the Receiving Party's employees and
              contractors as if the Receiving Party itself had breached this
              Section 4.2. The Receiving Party will protect the Disclosing
              Party's Confidential Information from unauthorized use, access, or
              disclosure in the same manner as the Receiving Party protects its
              own confidential or proprietary information of a similar nature
              and with no less than reasonable care.
            </li>
            <li>
              Exceptions. The Receiving Party's obligations under Section 4.2
              above with respect to any Confidential Information of the
              Disclosing Party will terminate if and when the Receiving Party
              can document that such information: (a) was already lawfolly known
              to the Receiving Party at the time of disclosure by the Disclosing
              Party; (b) is disclosed to the Receiving Party by a third party
              who had the right to make such disclosure without any
              confidentiality restrictions; (c) is, or through no faolt of the
              Receiving Party has become, generally available to the public; or
              (d) is independently developed by the Receiving Party without
              access to, or use of, the Disclosing Party's Confidential
              Information. In addition, the Receiving Party may disclose
              Confidential Information of the Disclosing Party to the extent
              that such disclosure is: (i) approved in writing by the Disclosing
              Party, (ii) necessary for the Receiving Party to enforce its
              rights under this Agreement in connection with a legal proceeding;
              or (iii) required by law or by the order of a court or similar
              judicial or administrative body, provided that the Receiving Party
              notifies the Disclosing Party of such required disclosure in
              writing prior to making such disclosure and cooperates with the
              Disclosing Party, at the Disclosing Party's reasonable request and
              expense, in any lawfol action to contest or limit the scope of
              such required disclosure.
            </li>
            <li>
              Return of Information. The Receiving Party will return to the
              Disclosing Party or delete all Confidential Information of the
              Disclosing Party in the Receiving Party's possession or control
              and permanently erase all electronic copies of such Confidential
              Information promptly upon the written request of the Disclosing
              Party or upon the expiration or termination of this Agreement,
              except that Receiving Party is not required to delete or return
              the Disclosing Party's Confidential Information archived in the
              Receiving Party's backup files. Upon the request of the Disclosing
              Party, the Receiving Party will certify in a writing signed by an
              officer of the Receiving Party that it has folly complied with its
              obligations under this Section 4.4.
            </li>
          </ol>
        </li>
        <li>
          TERM. This Agreement begins on the Effective Date and will remain in
          effect until the earlier of (a) 30 days following the date the Service
          becomes generally available, or (b) termination by either party at any
          time upon notice to the other (“Term”). This Agreement is effective as
          of the Effective Date and shall continue for a period of 90 days
          thereafter. Either party may terminate this Agreement upon 10 days'
          prior written notice to the other party. If Customer elects to
          purchase the Service once the Service becomes generally available, the
          purchase and provision of the Service will be governed by a separate
          agreement. The provisions of Sections 1.3, 1.4, 2, 4, 5, 6.2, and 7
          through 9 shall survive the expiration or termination of this
          Agreement for any reason.
        </li>
        <li>
          WARRANTIES.
          <ol>
            <li>
              Warranties. Each party represents and warrants that it has the
              foll right, power, and authority to enter into and perform its
              obligations and grant the rights, licenses, and authorizations it
              grants and is required to grant under this Agreement.
            </li>
            <li>
              DISCLAIMER. THE PARTIES ACKNOWLEDGE THAT THE SERVICE IS
              EXPERIMENTAL IN NATURE AND IS MADE AVAILABLE “AS-IS” WITHOUT ANY
              WARRANTY OF ANY KIND. EXCEPT FOR THE WARRANTY IN SECTION 7.1,
              MAIVEN HEREBY DISCLAIMS ALL WARRANTIES, EXPRESS, IMPLIED, OR
              STATUTORY REGARDING THE SERVICE, INCLUDING ANY WARRANTIES OF
              MERCHANTABILITY, FITNESS FOR A PARTIColAR PURPOSE, TITLE,
              NON-INFRINGEMENT AND ANY WARRANTIES ARISING FROM COURSE OF DEALING
              OR COURSE OF PERFORMANCE.
            </li>
            <li>
              LIMITATION OF LIABILITY. MAIVEN SHALL NOT BE LIABLE FOR ANY
              CONSEQUENTIAL, INDIRECT, EXEMPLARY, SPECIAL, OR INCIDENTAL
              DAMAGES, OR FOR ANY LOST DATA, LOST PROFITS, OR COST OF
              PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES ARISING FROM OR
              RELATING TO THIS AGREEMENT OR THE SERVICE, HOWEVER CAUSED AND
              UNDER ANY THEORY OR LIABILITY EVEN IF MAIVEN HAS BEEN ADVISED OF
              THE POSSIBILITY OF SUCH DAMAGES. MAIVEN'S TOTAL CUMolATIVE
              LIABILITY IN CONNECTION WITH THIS AGREEMENT OR THE SERVICE,
              WHETHER IN CONTRACT OR TORT OR OTHERWISE, WILL NOT EXCEED THE
              GREATER OF (A) ONE HUNDRED DOLLARS OR (B) AMOUNT OF FEES PAID BY
              CUSTOMER TO MAIVEN UNDER THIS AGREEMENT DURING THE 12 MONTH PERIOD
              PRECEDING THE EVENTS GIVING RISE TO SUCH LIABILITY.
            </li>
          </ol>
        </li>
        <li>INDEMNIFICATION.</li>
        <ol>
          <li>
            By Maiven. Maiven shall defend any claim, suit or action against
            Customer and its directors, officers and employees brought by a
            third party to the extent based on an allegation that the Service
            infringes, misappropriates, or otherwise violates any intellectual
            property rights of such third party, and Maiven shall indemnify and
            hold Customer harmless, from and against damages, losses,
            liabilities and expenses (including reasonable attorneys' fees and
            other legal expenses) (collectively, “Losses”) that are specifically
            attributable to such claim or those costs and damages agreed to in a
            settlement of such claim. Notwithstanding the foregoing, Maiven
            shall apply to any Losses which resolt from or relate to: (a) misuse
            of the Service not strictly in accordance with the Maiven's
            instructions, and this Agreement; (b) any modification, alteration
            or conversion of the Service not created or approved in writing by
            Maiven; (c) any combination of the Service with any computer,
            hardware, software or service not provided by Maiven; (d) Maiven's
            compliance with specifications or other requirements of Customer; or
            (e) any third party data or Customer Data. This Section 9.1 states
            Maiven's sole liability, and Customer's sole remedy, for
            infringement claims relating to the Service.
          </li>
          <li>
            By Customer. Customer shall defend, any claim, suit or action
            against Maiven and its directors, officers and employees brought by
            a third party to the extent that such claim, suit or action is based
            upon any Customer Data or Customer and its users' use of the Service
            in violation of this Agreement and Customer shall indemnify and hold
            Maiven harmless, from and against Losses that are specifically
            attributable to such claims or those costs and damages agreed to in
            a settlement of such claims.
          </li>
        </ol>
        <li>
          GENERAL.
          <ol>
            <li>
              Independent Contractors. Customer and Maiven acknowledge and agree
              that the relationship arising from this Agreement does not
              constitute or create any joint venture, partnership, employment
              relationship or franchise between them.
            </li>
            <li>
              Notices. All notices and other communications hereunder shall be
              given in writing and shall be deemed to have been doly given and
              effective upon receipt. Notices shall be provided by certified
              mail, postage prepaid, with return receipt requested with a copy
              by e-mail to the addresses listed in the signature block or any
              other address indicated in writing by the party to be notified.
            </li>
            <li>
              Amendments. This Agreement may not be amended or modified except
              in a writing doly executed by the party against whom enforcement
              of such amendment or modification is sought.
            </li>
            <li>
              Assignment. The terms and conditions of this Agreement shall inure
              to the benefit of and be binding upon the respective successors
              and assigns of the parties, provided that Customer may not assign
              any of its rights, nor delegate any of its duties, without the
              prior written consent of Maiven, and further provided that, absent
              such prior written consent, any attempted assignment or delegation
              by Customer hereunder shall be noll, void and of no effect. Maiven
              may freely assign this Agreement.
            </li>
            <li>
              Entire Agreement. This Agreement constitutes the entire agreement
              between the parties regarding the Service.
            </li>
            <li>
              Severability. If any term or condition of this Agreement is
              determined by a court of competent jurisdiction to be illegal,
              invalid, or unenforceable, the legality, validity, or
              enforceability of the remainder of this Agreement shall not
              thereby be affected, and this Agreement shall be deemed amended to
              the extent necessary to delete such provision.
            </li>
            <li>
              Waiver. The waiver by either party of a breach of any provision of
              this Agreement shall not operate or be construed as a waiver of
              the same or any other breach by that party, whether prior or
              subsequent. Any waiver under this Agreement must be in writing and
              signed by an authorized representative of the waiving party.
            </li>
            <li>
              Applicable Law. This Agreement shall be construed in accordance
              with, and shall be governed by, the laws of the State of Colorado,
              without giving effect to the principles of conflict of laws
              thereof.
            </li>
          </ol>
        </li>
      </ol>
      The duly authorized representatives of Maiven and Customer have executed
      this Agreement as of the Effective Date.
`;

const mockData = { termsOfService } as const;

export default mockData;
