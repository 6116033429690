import React from 'react';

import Text from 'src/components/Typography';

interface Props {
  title: string;
  description: string | JSX.Element;
  icon?: React.ReactNode;
}

const AssessmentSliderItem: React.FC<Props> = ({
  title,
  description,
  icon,
}) => {
  return (
    <div className='flex flex-col gap-2 px-10'>
      {icon}
      <Text as='h1' size='3xl' className='h-36 font-bold md:h-32 lg:h-28'>
        {title}
      </Text>
      {typeof description === 'string' ? (
        <Text className='h-24 font-normal md:h-20'>{description}</Text>
      ) : (
        <>{description}</>
      )}
    </div>
  );
};

export default AssessmentSliderItem;
