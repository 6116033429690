import { useQuery } from '@tanstack/react-query';

import { useApiClient } from 'src/api/hooks/useApiClient';
import { TermsOfService } from 'src/api/types';

export type UseGetTermsOfServiceParams =
  | { enabled: false }
  | { enabled: true; version: string; assessmentId: string };

export const useGetTermsOfService = (options: UseGetTermsOfServiceParams) => {
  const { getClient } = useApiClient();

  return useQuery({
    queryKey: ['terms-of-service'] as const,
    queryFn: async () => {
      if (!options.enabled) {
        return undefined;
      }

      const client = await getClient();

      const response = await client.get<TermsOfService>(
        `/assessments/${options.assessmentId}/terms-of-service/${options.version}`
      );

      return response.data;
    },
    enabled: options.enabled,
  });
};
