import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useApiClient } from 'src/api/hooks/useApiClient';
import { AssessmentDetails, AssessmentStatus } from 'src/api/types';

interface PatchAssessmentRequestParams {
  assessmentId: string;
  status: AssessmentStatus;
}

export const usePatchAssessment = () => {
  const { getClient } = useApiClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (requestParams: PatchAssessmentRequestParams) => {
      const client = await getClient();

      await client.patch(`/assessments/${requestParams.assessmentId}`, {
        status: requestParams.status,
      });
    },
    onSuccess: (_, variables) => {
      queryClient.setQueryData(
        ['assessment-details'],
        (old?: AssessmentDetails): AssessmentDetails | undefined => {
          return old
            ? {
                ...old,
                assessmentStatus: variables.status,
              }
            : old;
        }
      );
    },
  });
};
