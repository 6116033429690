import { XMarkIcon } from '@heroicons/react/24/outline';
import React from 'react';

import Text from 'src/components/Typography';
import AssessmentLeaveModal from 'src/pages/Assessment/components/AssessmentLeaveModal';

interface Props {
  /**
   * Decimal value between 0 and 1 representing the progress of the assessment.
   */
  progress: number;
}

const AssessmentFormHeader: React.FC<Props> = ({ progress }) => {
  return (
    <div className='flex h-16 flex-col'>
      {/* Heading */}
      <div className='relative isolate flex flex-grow items-center overflow-hidden bg-gray-100 px-4 before:flex-1'>
        <div className='flex flex-wrap items-center gap-x-4 gap-y-2'>
          <Text className='flex-grow text-center font-bold'>
            My Energy Assessment
          </Text>
        </div>
        <div className='flex flex-1 justify-end'>
          <AssessmentLeaveModal>
            <span className='sr-only'>Exit</span>
            <XMarkIcon className='h-6 w-6' />
          </AssessmentLeaveModal>
        </div>
      </div>

      {/* ProgressBar */}
      <div className='overflow-hidden bg-gray-200'>
        <div
          style={{ width: `${progress * 100}%` }}
          className='h-2 bg-yellow-500'
        />
      </div>
    </div>
  );
};

export default AssessmentFormHeader;
