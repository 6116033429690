import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAcceptTermsOfService } from 'src/api';
import MaivenLogo from 'src/assets/maiven.svg?react';
import Button from 'src/components/Button';
import FullPageLayout from 'src/components/Layout/FullPageLayout';
import Text from 'src/components/Typography';
import { useAssessmentContext } from 'src/contexts/AssessmentContext';
import AssessmentLeaveModal from 'src/pages/Assessment/components/AssessmentLeaveModal';

const TermsOfService = () => {
  const termsContainerRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { termsOfService, details } = useAssessmentContext();
  const {
    mutateAsync: acceptTermsOfService,
    isPending: isAcceptingTermsOfService,
  } = useAcceptTermsOfService();
  const [scrolledToTheEnd, setScrolledToTheEnd] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const container = termsContainerRef.current;

      if (!container) return;

      if (
        container.scrollTop + container.clientHeight >=
        container.scrollHeight
      ) {
        setScrolledToTheEnd(true);
      }
    };

    const container = termsContainerRef.current;

    container?.addEventListener('scroll', handleScroll);

    return () => {
      container?.removeEventListener('scroll', handleScroll);
    };
  }, [setScrolledToTheEnd]);

  useEffect(() => {
    if (termsOfService?.acceptedAt) {
      navigate('/assessment');
    }
  }, [navigate, termsOfService?.acceptedAt]);

  const onAcceptTermsOfService = async () => {
    if (scrolledToTheEnd && termsOfService) {
      try {
        await acceptTermsOfService({
          id: termsOfService.id,
          assessmentId: details.assessmentId,
        });

        navigate('/assessment');
      } catch {
        return;
      }
    }
  };

  useEffect(() => {
    if (!termsOfService) {
      navigate('/assessment');
    }
  });

  return (
    <FullPageLayout className='mx-auto flex flex-col bg-gray-400 md:py-14 lg:py-20'>
      <div className='flex-middle-middle flex flex-grow flex-col gap-7 bg-white px-8 py-5 md:mx-auto md:w-3/4'>
        {/* Logo */}
        <MaivenLogo className='h-12 w-12' />

        {/* Title */}
        <div className='flex-middle-middle flex w-3/4 flex-col gap-4 text-center md:w-1/2'>
          <Text size='xl' className='font-bold'>
            Please accept the Maiven Terms&nbsp;of&nbsp;Service to continue
          </Text>
          <Text size='base'>Scroll to the end to accept</Text>
        </div>

        {/* Content */}
        <div
          className='h-1 w-full flex-grow overflow-auto bg-gray-200 p-3'
          ref={termsContainerRef}
        >
          <span
            className='wysiwyg'
            dangerouslySetInnerHTML={{
              __html: termsOfService?.text ?? '',
            }}
          />
        </div>

        {/* Buttons */}
        <div className='flex-middle-middle flex flex-col gap-3'>
          <Button
            disabled={!scrolledToTheEnd || isAcceptingTermsOfService}
            onClick={() => {
              void onAcceptTermsOfService();
            }}
          >
            Accept
          </Button>
          <AssessmentLeaveModal
            triggerButtonProps={{
              buttonType: 'link',
            }}
          >
            Sign out
          </AssessmentLeaveModal>
        </div>
      </div>
    </FullPageLayout>
  );
};

export default TermsOfService;
