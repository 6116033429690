import { useRef } from 'react';

type ArgumentTypes<F extends CallableFunction> = F extends (
  ...args: infer A
) => unknown
  ? A
  : never;

export const useDebounce = <CB extends CallableFunction>(
  func: CB,
  delay: number
) => {
  const timeoutRef = useRef<number | null>(null);

  const debouncedFunc = (...args: ArgumentTypes<typeof func>) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      func(...args);
    }, delay);
  };

  return debouncedFunc;
};
