import React from 'react';

import Text from 'src/components/Typography';

interface FormFieldLabelProps {
  ['data-id']: string;
  label?: string;
  htmlFor?: string;
}

const FormFieldLabel: React.FC<FormFieldLabelProps> = ({
  label,
  'data-id': dataId,
  htmlFor,
}) => {
  if (!label) {
    return null;
  }

  return (
    <Text id={`${dataId}-label`} htmlFor={htmlFor} as='label' size='sm'>
      {label}
    </Text>
  );
};

export default FormFieldLabel;
