import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { AssessmentStatus } from 'src/api';
import AssessmentCompleteCover from 'src/assets/assessment-completed-cover.jpeg';
import MaivenLogo from 'src/assets/maiven.svg';
import Wave from 'src/assets/wave.jpeg';
import Button from 'src/components/Button';
import Text from 'src/components/Typography';
import { useAssessmentContext } from 'src/contexts/AssessmentContext';
import AssessmentLeaveModal from 'src/pages/Assessment/components/AssessmentLeaveModal';
import AssessmentSliderItem from 'src/pages/Assessment/components/AssessmentSliderItem';

const AssessmentComplete: React.FC = () => {
  const {
    details: { assessmentStatus },
  } = useAssessmentContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (assessmentStatus !== AssessmentStatus.COMPLETE) {
      navigate('/terms');
    }
  }, [navigate, assessmentStatus]);

  return (
    <div
      style={{ backgroundImage: `url('${AssessmentCompleteCover}')` }}
      className={`relative min-h-dvh bg-cover bg-center`}
    >
      <div className='absolute flex h-full w-full justify-center bg-black bg-opacity-50 text-white'>
        <div className='flex h-full flex-col justify-between gap-3 py-6 text-center md:w-1/2 md:justify-center md:px-0 md:py-0'>
          <AssessmentSliderItem
            icon={
              <div
                className='relative mx-auto h-24 w-24 rounded-xl bg-cover bg-center p-4'
                style={{ backgroundImage: `url('${Wave}')` }}
              >
                <img src={MaivenLogo} alt='Maiven Logo' />
              </div>
            }
            title='Your report is generating!'
            description={
              <div className='h-24 md:h-20'>
                <Text className='font-bold'>Estimated Time: 3 minutes</Text>
                <Text>Feel free to close this screen and come back later.</Text>
              </div>
            }
          />

          <div className='flex flex-col gap-2 px-10'>
            <Button
              buttonStyle='light'
              onClick={() => {
                window.location.href = 'https://www.askmaiven.com/';
              }}
            >
              Exit
            </Button>
            <AssessmentLeaveModal
              triggerButtonProps={{
                inverted: true,
                buttonType: 'link',
              }}
              alternateMessage={<>Thanks for finishing the assessment.</>}
            >
              Sign out
            </AssessmentLeaveModal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssessmentComplete;
