import { yupResolver } from '@hookform/resolvers/yup';
import React, { useMemo } from 'react';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';

import {
  AssessmentResult,
  usePatchUser,
  usePatchUserProperty,
  UserPropertyOwnershipType,
} from 'src/api';
import { useGetUserUploadsSignedUrls } from 'src/api/hooks/mutations/useGetUserUploadsSignedUrls';
import Input from 'src/components/Input';
import PictureInput from 'src/components/PictureInput';
import Select, { SelectItem } from 'src/components/Select';
import { useDebounce } from 'src/hooks/useDebounce';
import { uploadFileToUrl } from 'src/lib';

interface Props {
  userId: string;
  propertyId: string;
  coreField: string;
  result?: AssessmentResult;
}

const formLabels: Record<string, string | undefined> = {
  first_name: 'First Name',
  last_name: 'Last Name',
  address: 'Address',
  city: 'City',
  state: 'State',
  zip: 'Zip',
  'own/rent': 'What describes you?',
  profile_pic: 'Profile Pic (optional)',
  residents_count: 'Number of residents (including yourself)',
};

const ownRentItems: SelectItem[] = [
  { label: 'Own', value: 'own' },
  { label: 'Rent', value: 'rent' },
  { label: 'Landlord', value: 'landlord' },
];

const AssessmentFormCoreField: React.FC<Props> = ({
  coreField,
  propertyId,
  userId,
  result,
}) => {
  const formSchema = useMemo(
    () =>
      yup.object({
        [coreField]: yup.string().required('This field is required'),
      }),
    [coreField]
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<yup.InferType<typeof formSchema>>({
    resolver: yupResolver(formSchema),
  });

  const { mutateAsync: patchUser, isPending: isLoadingPatchUser } =
    usePatchUser();
  const {
    mutateAsync: patchUserProperty,
    isPending: isLoadingPatchUserProperty,
  } = usePatchUserProperty();
  const {
    mutateAsync: getUserUploadsSignedUrls,
    isPending: isLoadingUserUploadsSignedUrls,
  } = useGetUserUploadsSignedUrls();

  const debouncedPatchUser = useDebounce(
    async (data: Record<string, string>) => {
      if (data.first_name || data.last_name) {
        await patchUser({
          coreField,
          userId,
          firstName: data.first_name,
          lastName: data.last_name,
        });
      }
    },
    200
  );

  const debouncedPatchUserProperty = useDebounce(
    async (data: Record<string, string>) => {
      if (
        data.address ||
        data.city ||
        data.state ||
        data.zip ||
        data.residents_count ||
        data['own/rent']
      ) {
        await patchUserProperty({
          coreField,
          userId,
          propertyId,
          address1: data.address,
          city: data.city,
          state: data.state,
          zip: data.zip,
          residentsCount: data.residents_count,
          ownershipType:
            data['own/rent'] === 'own'
              ? UserPropertyOwnershipType.OWN
              : data['own/rent'] === 'landlord'
                ? UserPropertyOwnershipType.LANDLORD
                : UserPropertyOwnershipType.RENT,
        });
      }
    },
    200
  );

  const onSubmit = (data: Record<string, string>) => {
    debouncedPatchUser(data);
    debouncedPatchUserProperty(data);
  };

  return (
    <form
      id={`${coreField}-form`}
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onBlur={handleSubmit(onSubmit)}
      className='flex flex-col gap-3'
    >
      <Controller
        key={coreField}
        name={coreField}
        defaultValue={result?.value ?? ''}
        control={control}
        render={({ field }) => {
          const label = formLabels[field.name];
          const fieldProps = {
            ['data-id']: field.name,
            label,
            ...field,
            errors,
            disabled:
              !!field.disabled ||
              isLoadingPatchUser ||
              isLoadingPatchUserProperty,
          };

          switch (field.name) {
            case 'first_name':
            case 'last_name':
            case 'address':
            case 'city':
            case 'state':
            case 'zip':
            case 'residents_count':
              return (
                <Input
                  autoComplete='off'
                  {...fieldProps}
                  type={field.name === 'residents_count' ? 'number' : undefined}
                />
              );
            case 'own/rent':
              return (
                <Select
                  items={ownRentItems}
                  placeholder='Choose an option...'
                  {...fieldProps}
                />
              );
            case 'profile_pic':
              return <></>;
            default:
              return <div>TODO: Handle core_field type = {field.name}</div>;
          }
        }}
      />
      {coreField === 'profile_pic' && (
        <PictureInput
          data-id={coreField}
          value={result?.value}
          label={formLabels[coreField]}
          disabled={isLoadingUserUploadsSignedUrls}
          accept='image/*'
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onChange={async (e) => {
            const file = e.target.files?.[0];

            if (!file) {
              return;
            }

            const fileExtension = file.name.split('.').pop();
            const filename = `${coreField}.${fileExtension}`;

            const response = await getUserUploadsSignedUrls({
              userId,
              files: [{ filename, contentType: file.type }],
            });

            const uploadFile = response.files[0];

            if (!uploadFile?.url) {
              return;
            }

            await uploadFileToUrl(file, uploadFile.url);

            await patchUser({
              coreField,
              userId,
              avatarPath: uploadFile.filename,
              overrideNewValue: uploadFile.getUrl,
            });
          }}
        />
      )}
    </form>
  );
};

export default AssessmentFormCoreField;
