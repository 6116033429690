import { PhotoIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import React, { InputHTMLAttributes, useRef } from 'react';

import Text from 'src/components/Typography';

interface FileUploadProps extends InputHTMLAttributes<HTMLInputElement> {
  ['data-id']: string;
  onFiles: (files: File[]) => Promise<void>;
}

const FileUpload: React.FC<FileUploadProps> = ({
  onFiles,
  'data-id': dataId,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  return (
    <div
      className={clsx(
        'mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10',
        {
          'cursor-not-allowed bg-gray-100': props.disabled,
          'cursor-pointer': !props.disabled,
        }
      )}
      onDrop={(e) => {
        e.preventDefault();

        if (props.disabled) {
          return;
        }

        const files: File[] = [];

        if (e.dataTransfer.items) {
          [...e.dataTransfer.items].forEach((item) => {
            if (item.kind !== 'file') return;

            const file = item.getAsFile();

            if (!file) return;

            files.push(file);
          });
        } else {
          [...e.dataTransfer.files].forEach((file) => {
            files.push(file);
          });
        }

        void onFiles(files);
      }}
      onDragOver={(e) => {
        e.preventDefault();
      }}
      onClick={() => {
        if (props.disabled) {
          return;
        }

        inputRef.current?.click();
      }}
    >
      <div className='text-center'>
        <PhotoIcon
          aria-hidden='true'
          className='mx-auto h-12 w-12 text-gray-400'
        />
        <div className='mt-4 flex justify-center'>
          <Text
            as='label'
            data-id={`${dataId}-label`}
            htmlFor={`${dataId}-field`}
            className={clsx(
              'font-bold',
              'focus-input-within rounded-md',
              props.disabled
                ? 'base-form-field-disabled cursor-not-allowed'
                : 'cursor-pointer hover:text-gray-700'
            )}
          >
            Upload a file
            <input
              id={`${dataId}-field`}
              type='file'
              className='sr-only'
              {...props}
              onChange={(e) => {
                const reset = () => {
                  if (inputRef.current) {
                    inputRef.current.value = '';
                  }
                };

                if (props.disabled) {
                  reset();

                  return;
                }

                const files = e.target.files;

                if (!files?.length) {
                  return;
                }

                void onFiles([...files]).finally(reset);
              }}
              ref={inputRef}
            />
          </Text>
          <Text as='span' className='pl-1'>
            or drag and drop
          </Text>
        </div>

        <Text as='span' size='xs' className='text-gray-400'>
          {props.multiple ? 'Maximum 12 files. ' : 'Maximum 1 file. '}
          PNG, JPG, JPEG, GIF, PDF up to 10MB.
        </Text>
      </div>
    </div>
  );
};

export default FileUpload;
