import clsx from 'clsx';
import React, { useMemo } from 'react';

import AssessmentFormQuestion from './AssessmentFormQuestion';
import { AssessmentFormSubSectionContentQuestion } from './hooks/useAssessmentFormSubSections';
import { AssessmentQuestion, AssessmentResult } from 'src/api';
import Text from 'src/components/Typography';

interface Props {
  assessmentId: string;
  questions: AssessmentFormSubSectionContentQuestion[];
  allQuestions: Record<string, AssessmentQuestion>;
  results: Record<string, AssessmentResult>;
  sectionType?: string;
}

const AssessmentFormQuestions: React.FC<Props> = ({
  assessmentId,
  questions,
  allQuestions,
  results,
  sectionType,
}) => {
  const groups = useMemo(() => {
    if (!sectionType) return;

    return questions.reduce((a, c) => {
      if (!c.question_group) return a;

      const group = a.find((g) => g[0].question_group === c.question_group);

      if (group) {
        group.push(c);
      } else {
        a.push([c]);
      }

      return a;
    }, [] as AssessmentFormSubSectionContentQuestion[][]);
  }, [sectionType, questions]);

  return (
    <div
      id='assessment-form-questions'
      className={clsx('flex flex-col gap-3', !sectionType && 'mx-4')}
    >
      {sectionType && (
        <div className='flex flex-col gap-12 bg-gray-50 px-4 py-3'>
          {groups?.map((group) => (
            <div key={group[0].question_group} className='flex flex-col gap-2'>
              <Text as='label' className='font-bold text-gray-700'>
                {group[0].question_group}
              </Text>
              <div className='flex flex-col gap-2'>
                {group.map(({ question_id, dependingQuestions }) => (
                  <AssessmentFormQuestion
                    key={question_id}
                    assessmentId={assessmentId}
                    questionId={question_id}
                    dependingQuestions={dependingQuestions}
                    question={allQuestions[question_id]}
                    result={results[question_id]}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
      {!sectionType &&
        questions.map(({ question_id, dependingQuestions }) => (
          <AssessmentFormQuestion
            key={question_id}
            assessmentId={assessmentId}
            questionId={question_id}
            dependingQuestions={dependingQuestions}
            question={allQuestions[question_id]}
            result={results[question_id]}
          />
        ))}
    </div>
  );
};

export default AssessmentFormQuestions;
