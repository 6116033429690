import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useApiClient } from 'src/api/hooks/useApiClient';
import { AssessmentDetails, AssessmentResultType } from 'src/api/types';

interface PutAssessmentQuestionResultRequestParams {
  assessmentId: string;
  questionId: string;
  value: string;
  type: AssessmentResultType;
  userInput?: string;
  shouldRemoveUserInput?: boolean;
  overrideNewValue?: string;
  allowMultiple?: boolean;
  dependingQuestionIds: string[];
}

interface PutAssessmentQuestionResultResponse {
  resultId: string;
  resultValueId?: string;
}

export const usePutAssessmentQuestionResult = () => {
  const { getClient } = useApiClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (
      requestParams: PutAssessmentQuestionResultRequestParams
    ) => {
      const client = await getClient();

      const { data } = await client.put<PutAssessmentQuestionResultResponse>(
        `/assessments/${requestParams.assessmentId}/questions/${requestParams.questionId}/results`,
        {
          value: requestParams.value,
          type: requestParams.type,
          userInput: requestParams.userInput,
          shouldRemoveUserInput: requestParams.shouldRemoveUserInput,
          dependingQuestionIds: requestParams.dependingQuestionIds,
        }
      );

      return data;
    },
    onSuccess: (data, variables) => {
      queryClient.setQueryData(
        ['assessment-details'],
        (old?: AssessmentDetails): AssessmentDetails | undefined => {
          if (old) {
            for (const dependindQuestionId of variables.dependingQuestionIds) {
              delete old.results[dependindQuestionId];
            }

            return {
              ...old,
              results: {
                ...old.results,
                [variables.questionId]: {
                  id: data.resultId,
                  value: variables.value,
                  assessmentQuestionId: variables.questionId,
                  type: variables.type,
                  values: variables.userInput
                    ? [
                        ...(variables.allowMultiple
                          ? [
                              ...(old.results[variables.questionId]?.values ??
                                []),
                            ]
                          : []),
                        {
                          id: data.resultValueId ?? '',
                          value:
                            variables.overrideNewValue ?? variables.userInput,
                        },
                      ]
                    : [],
                },
              },
            };
          }

          return old;
        }
      );
    },
  });
};
