import React from 'react';

import AssessmentFormCoreField from './AssessmentFormCoreField';
import { AssessmentResult } from 'src/api';

interface Props {
  coreFields: string[];
  userId: string;
  propertyId: string;
  results: Record<string, AssessmentResult>;
}

const AssessmentFormCoreFields: React.FC<Props> = ({
  coreFields,
  userId,
  propertyId,
  results,
}) => {
  return (
    <div id='assessment-form-core-fields' className='mx-4 flex flex-col gap-3'>
      {coreFields.map((coreField) => (
        <AssessmentFormCoreField
          key={coreField}
          coreField={coreField}
          userId={userId}
          propertyId={propertyId}
          result={results[coreField]}
        />
      ))}
    </div>
  );
};

export default AssessmentFormCoreFields;
