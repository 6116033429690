import clsx from 'clsx';
import React, { ElementType, forwardRef, ReactElement } from 'react';

interface AsProp<T extends ElementType> {
  as?: T;
}

export interface TextProps {
  size?: 'xs' | 'sm' | 'base' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl';
}

type TextPropsType<T extends ElementType> = AsProp<T> &
  React.ComponentPropsWithoutRef<T> & {
    className?: string;
    children?: React.ReactNode;
  } & TextProps;

type TextRef<T extends ElementType> = React.ComponentPropsWithRef<T>['ref'];

// Need to disable eslint rule because of the react-refresh import
// because the default export needs the type casting

// eslint-disable-next-line react-refresh/only-export-components
const Text = forwardRef(
  <T extends ElementType = 'p'>(
    { as, className, children, size, ...props }: TextPropsType<T>,
    ref: TextRef<T>
  ): ReactElement => {
    const Component = as ?? 'p';

    return (
      <Component
        ref={ref}
        {...props}
        className={clsx(className, {
          'text-xs': size === 'xs',
          'text-sm': size === 'sm',
          'text-base': size === 'base',
          'text-lg': size === 'lg',
          'text-xl': size === 'xl',
          'text-2xl': size === '2xl',
          'text-3xl': size === '3xl',
          'text-4xl': size === '4xl',
        })}
      >
        {children}
      </Component>
    );
  }
);

Text.displayName = 'Text';

export default Text as <T extends ElementType = 'p'>(
  props: TextPropsType<T> & { ref?: TextRef<T> }
) => ReactElement;
