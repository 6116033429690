import { createContext } from 'react';

import { AssessmentDetails, TermsOfService } from 'src/api';

export interface AssessmentContextValue {
  details: AssessmentDetails;
  termsOfService?: TermsOfService;
}

const AssessmentContext = createContext<AssessmentContextValue>(
  {} as AssessmentContextValue
);

export default AssessmentContext;
