import clsx from 'clsx';
import { ButtonHTMLAttributes, forwardRef } from 'react';

type ButtonTypes = 'normal' | 'pill' | 'outline' | 'link';
type ButtonSizes = 'xs' | 'sm' | 'base' | 'lg' | 'xl';
type ButtonStyles = 'dark' | 'light' | 'accent-1';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  buttonType?: ButtonTypes;
  size?: ButtonSizes;
  buttonStyle?: ButtonStyles;
  inverted?: boolean;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      buttonType = 'normal',
      size = 'base',
      buttonStyle = 'dark',
      inverted = false,

      className,
      disabled,
      ...buttonProps
    },
    ref
  ) => {
    return (
      <button
        ref={ref}
        className={clsx(
          // Button Rounded
          buttonType === 'pill' ? 'rounded-3xl' : 'rounded-md',
          {
            ...(disabled && {
              'cursor-not-allowed focus-visible:outline-none': true,
              'text-gray-500': true,
              'bg-gray-200': true,
            }),
            ...(!disabled && {
              'focus-btn focus-visible:outline-purple-500': true,

              ...(['normal', 'pill'].includes(buttonType) && {
                ...(buttonStyle === 'dark' && {
                  'text-gray-50': true,
                  'bg-gray-900': !inverted,
                  'hover:bg-gray-800': !inverted,

                  'bg-gray-400': inverted,
                  'hover:bg-gray-500': inverted,
                }),

                ...(buttonStyle === 'light' && {
                  'text-gray-900': true,
                  'bg-gray-50': !inverted,
                  'hover:bg-gray-200': !inverted,

                  'bg-gray-200': inverted,
                  'hover:bg-gray-400': inverted,
                }),

                ...(buttonStyle === 'accent-1' && {
                  'text-gray-900': true,
                  'bg-yellow-500': true,
                  'hover:bg-yellow-400': true,
                }),
              }),

              ...(buttonType === 'outline' && {
                'outline outline-2 outline-offset-0': true,

                'text-gray-900': !inverted,
                'text-gray-300': inverted,

                'hover:text-gray-50': buttonStyle === 'dark',
                'outline-gray-600 hover:bg-gray-900 hover:outline-gray-900':
                  buttonStyle === 'dark' && !inverted,
                'outline-gray-400 hover:bg-gray-400':
                  buttonStyle === 'dark' && inverted,

                'hover:bg-gray-200 hover:text-gray-900':
                  buttonStyle === 'light',
                'outline-gray-600': buttonStyle === 'light' && !inverted,

                'outline-yellow-500 hover:bg-yellow-200 hover:text-gray-900':
                  buttonStyle === 'accent-1',
              }),

              ...(buttonType === 'link' && {
                'hover:text-gray-400':
                  buttonStyle === 'dark' || buttonStyle === 'light',
                'hover:text-yellow-200': buttonStyle === 'accent-1',
                'text-gray-50': inverted,
                'text-gray-900': !inverted,
              }),
            }),

            // Button Sizes
            'btn-xs': size === 'xs',
            'btn-sm': size === 'sm',
            'btn-base': size === 'base',
            'btn-lg': size === 'lg',
            'btn-xl': size === 'xl',
          },
          className
        )}
        disabled={disabled}
        {...buttonProps}
      />
    );
  }
);

Button.displayName = 'Button';

export default Button;
