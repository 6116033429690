export const waitMs = async (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const isUserInputResult = (value: string): boolean =>
  value === 'USER_INPUT';

export const uploadFileToUrl = async (
  file: File,
  presignedUrl: string
): Promise<void> => {
  const fileData = await fetch(URL.createObjectURL(file));
  const imageBlob = await fileData.blob();

  await fetch(presignedUrl, {
    method: 'PUT',
    body: imageBlob,
  });
};
