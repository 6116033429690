import { Navigate, Route, Routes } from 'react-router-dom';

import Assessment from './Assessment';
import AssessmentComplete from './AssessmentComplete';
import Login from './Login';
import Logout from './Logout';
import TermsOfService from './TermsOfService';
import ButtonTesting from 'src/components/Button/ButtonTesting';
import RootLayout from 'src/components/Layout/RootLayout';
import PrivateRoute from 'src/components/PrivateRoute';
import Text from 'src/components/Typography';
import AssessmentProvider from 'src/contexts/AssessmentContext';

const Pages = () => {
  return (
    <Routes>
      <Route path='/' element={<RootLayout />}>
        <Route path='login' element={<Login />} />
        <Route path='logout' element={<Logout />} />

        <Route index element={<Navigate to='/terms' />} />

        <Route
          path='terms'
          element={
            <PrivateRoute>
              <AssessmentProvider>
                <TermsOfService />
              </AssessmentProvider>
            </PrivateRoute>
          }
        />

        <Route
          path='assessment'
          element={
            <PrivateRoute>
              <AssessmentProvider>
                <Assessment />
              </AssessmentProvider>
            </PrivateRoute>
          }
        />

        <Route
          path='assessment-complete'
          element={
            <PrivateRoute>
              <AssessmentProvider>
                <AssessmentComplete />
              </AssessmentProvider>
            </PrivateRoute>
          }
        />

        <Route path='button-testing' element={<ButtonTesting />} />

        <Route path='*' element={<Text>Page not found</Text>} />
      </Route>
    </Routes>
  );
};

export default Pages;
