import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { AssessmentFormSectionContent } from './types';
import {
  AssessmentResult,
  AssessmentTemplateContentQuestion,
  AssessmentTemplateContentSectionSubSection,
} from 'src/api';

export type AssessmentFormSubSectionContentQuestion =
  AssessmentTemplateContentQuestion & {
    dependingQuestions: string[];
  };

export interface AssessmentFormSubSectionContent {
  header: string;
  order: number;
  description: string;
  shouldPrevSection: boolean;
  prevSubSection?: string;
  nextSubSection?: string;
  shouldNextSection: boolean;
  nextButtonLabel?: string;
  overrideNextButtonAction?: () => void;
  questions: AssessmentFormSubSectionContentQuestion[];
  coreDataFields: string[];
  sectionType?: string;
}

export type AssessmentFormSubSectionContentByName = Record<
  string,
  AssessmentFormSubSectionContent
>;

const resolveQuestionDependencies = (
  subsections: AssessmentTemplateContentSectionSubSection[],
  questions: AssessmentTemplateContentQuestion[],
  results: Record<string, AssessmentResult>
): AssessmentFormSubSectionContentQuestion[] => {
  return questions.reduce<AssessmentFormSubSectionContentQuestion[]>((a, c) => {
    const dependsOnValues = Array.isArray(c.depends_on_value)
      ? c.depends_on_value
      : c.depends_on_value
        ? [c.depends_on_value]
        : [];

    if (c.depends_on && dependsOnValues.some(Boolean)) {
      const dependsOnQuestion = questions.find(
        (q) => q.question_order === c.depends_on
      );

      if (dependsOnQuestion) {
        const result = results[dependsOnQuestion.question_id];
        const hasValue =
          dependsOnValues.includes(result?.value) ||
          result?.values.some((r) => dependsOnValues.includes(r.value));

        if (!hasValue) {
          return a;
        }
      }
    }

    const dependingQuestions: string[] = [];

    dependingQuestions.push(
      ...subsections.reduce((acc, s) => {
        if (s.depends_on === c.question_id) {
          const questionIds = s.questions?.map((q) => q.question_id) ?? [];

          acc.push(...questionIds);
        }

        return acc;
      }, [] as string[])
    );
    dependingQuestions.push(
      ...questions.reduce((acc, q) => {
        if (q.depends_on === c.question_order) {
          acc.push(q.question_id);
        }

        return acc;
      }, [] as string[])
    );

    a.push({ ...c, dependingQuestions });

    return a;
  }, []);
};

export const useAssessmentFormSubSections = (
  section: AssessmentFormSectionContent,
  results: Record<string, AssessmentResult>
) => {
  const { subsectionNames, subsections } = useMemo(
    () =>
      section.subsections.reduce(
        (a, c, idx, arr) => {
          const getKey = (
            item: AssessmentTemplateContentSectionSubSection
          ): string => item.section_order + item.header;

          const nextSubSection =
            idx < arr.length - 1 ? getKey(arr[idx + 1]) : undefined;
          const prevSubSection = idx > 0 ? getKey(arr[idx - 1]) : '0cover';

          const questions = resolveQuestionDependencies(
            section.subsections,
            c.questions ?? [],
            results
          );

          const key = getKey(c);

          a.subsectionNames.push(key);

          a.subsections[key] = {
            description: c.description,
            header: c.header,
            order: c.section_order,
            questions,
            coreDataFields: c.core_data_fields ?? [],
            sectionType: c.section_type,
            nextSubSection,
            prevSubSection,
            shouldNextSection: !nextSubSection,
            shouldPrevSection: !prevSubSection,
            nextButtonLabel: section.nextButtonLabel,
            overrideNextButtonAction: section.overrideNextButtonAction,
          };

          return a;
        },
        {
          subsectionNames: ['0cover'] as string[],
          subsections: {
            '0cover': {
              description: section.cover.body,
              header: 'cover',
              questions: [],
              order: 0,
              shouldNextSection: !!section.overrideNextButtonAction,
              shouldPrevSection: true,
              nextButtonLabel: section.nextButtonLabel,
              overrideNextButtonAction: section.overrideNextButtonAction,
              nextSubSection: `${section.subsections[0]?.section_order}${section.subsections[0]?.header}`,
              coreDataFields: [],
            },
          } as AssessmentFormSubSectionContentByName,
        }
      ),
    [section, results]
  );

  const [searchParams, setSearchParams] = useSearchParams();

  const [currentSubsection, setCurrentSubsection] = useState<
    string | undefined
  >(subsectionNames[0]);

  useEffect(() => {
    const currentSubsectionSearchParam = searchParams.get('currentSubsection');

    if (currentSubsectionSearchParam) {
      setCurrentSubsection(currentSubsectionSearchParam);
      setSearchParams((current) => {
        current.delete('currentSubsection');

        return current;
      });
    }
  }, [searchParams, setCurrentSubsection, setSearchParams]);

  return {
    subsectionNames,
    subsections,
    currentSubsection,
    setCurrentSubsection: (str: string) => setCurrentSubsection(str),
    onPreviousSubsection: () => {
      if (!currentSubsection) return;

      const subsection = subsections[currentSubsection];

      if (subsection.prevSubSection) {
        setCurrentSubsection(subsection.prevSubSection);
      }
    },
    onNextSubsection: () => {
      if (!currentSubsection) return;

      const subsection = subsections[currentSubsection];

      if (subsection.nextSubSection) {
        setCurrentSubsection(subsection.nextSubSection);
      }
    },
  };
};
