export const logger = {
  error: (message: string, obj?: unknown) => {
    // eslint-disable-next-line no-console
    console.error(...[message, obj].filter(Boolean));
  },
  info: (message: string, obj?: unknown) => {
    // eslint-disable-next-line no-console
    console.info(...[message, obj].filter(Boolean));
  },
  debug: (message: string, obj?: unknown) => {
    // eslint-disable-next-line no-console
    console.debug(...[message, obj].filter(Boolean));
  },
};
