import clsx from 'clsx';
import { forwardRef, InputHTMLAttributes } from 'react';

import Checkbox from 'src/components/Checkbox';
import FormFieldLabel from 'src/components/FormFieldLabel';

export interface CheckboxItem<TValue extends string | number = string> {
  label: string;
  value: TValue;
}

interface CheckboxesProps<TValue extends string | number = string>
  extends InputHTMLAttributes<HTMLInputElement> {
  ['data-id']: string;
  label?: string;
  items: CheckboxItem<TValue>[];
  values: { id: string; value: TValue }[];
  onChecked: (value: TValue) => void;
  onUnChecked: (idx: number) => void;
}

const Checkboxes = forwardRef<HTMLInputElement, CheckboxesProps>(
  (
    {
      items,
      'data-id': dataId,
      label,
      values,
      onChecked,
      onUnChecked,
      ...props
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _ref
  ) => {
    return (
      <fieldset>
        <FormFieldLabel data-id={`${dataId}-field-label`} label={label} />
        <div
          className={clsx(
            'flex flex-col justify-center gap-2',
            label && 'mt-1'
          )}
        >
          {items.map((item, idx) => {
            const valueIdx = values.findIndex((v) => v.value === item.value);

            return (
              <Checkbox
                key={item.label}
                label={item.label}
                value={item.value}
                data-id={`${dataId}-${idx}`}
                checked={valueIdx >= 0}
                disabled={props.disabled}
                onChange={(e) => {
                  if (e.target.checked) {
                    onChecked(item.value);

                    return;
                  }

                  if (valueIdx) {
                    onUnChecked(valueIdx);
                  }
                }}
              />
            );
          })}
        </div>
      </fieldset>
    );
  }
);

Checkboxes.displayName = 'Checkboxes';

export default Checkboxes;
