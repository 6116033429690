import clsx from 'clsx';
import React, {
  ForwardedRef,
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';

import CarouselControl from './components/CarouselControl';
import Swipeable from 'src/components/Swipeable';

interface CarouselProps {
  containerClassName?: string;
  reportSeenCount?: (count: number) => void;
  nextButtonRef?: ForwardedRef<HTMLButtonElement>;
  prevButtonRef?: ForwardedRef<HTMLButtonElement>;
}

const Carousel: React.FC<PropsWithChildren<CarouselProps>> = ({
  children,
  containerClassName,
  reportSeenCount,
  nextButtonRef,
  prevButtonRef,
}) => {
  const slides = React.Children.toArray(children);

  const [currentSlide, setCurrentSlide] = useState(0);
  const [itemsSeen, setItemsSeen] = useState<Record<number, boolean>>({});

  useEffect(() => {
    setItemsSeen((prev) => ({ ...prev, [0]: true }));
  }, []);

  useEffect(() => {
    reportSeenCount?.(Object.values(itemsSeen).filter(Boolean).length);
  }, [itemsSeen, reportSeenCount]);

  const nextSlide = useCallback(() => {
    setCurrentSlide((prev) => {
      const value = prev + 1;

      const newValue = value > slides.length - 1 ? 0 : value;

      setItemsSeen((p) => ({ ...p, [newValue]: true }));

      return newValue;
    });
  }, [slides.length]);

  const prevSlide = useCallback(() => {
    setCurrentSlide((prev) => {
      const value = prev - 1;

      const newValue = value < 0 ? slides.length - 1 : value;

      return newValue;
    });
  }, [slides.length]);

  return (
    <div className={clsx('relative w-full', containerClassName)}>
      {/* <!-- Carousel Items --> */}

      <Swipeable
        onSwipe={(deltaX) => {
          if (deltaX < 0) nextSlide();
          if (deltaX > 0) prevSlide();
        }}
      >
        {slides.map((slide, idx) => (
          <div
            key={idx}
            className={clsx(
              'h-full pb-8 transition delay-700 duration-300 ease-in-out',
              {
                hidden: currentSlide !== idx,
                'translate-x-full opacity-0': currentSlide !== idx,
              }
            )}
            aria-current={currentSlide === idx}
          >
            {slide}
          </div>
        ))}
      </Swipeable>

      {/* <!-- Slider indicators --> */}
      <div className='absolute bottom-5 left-1/2 z-30 flex -translate-x-1/2 space-x-3 rtl:space-x-reverse'>
        {Array.from({ length: slides.length }).map((_, idx) => (
          <button
            key={idx}
            type='button'
            className={clsx(
              'focus-btn h-2 w-2 rounded-full bg-gray-400 focus-visible:outline-purple-500',
              {
                'bg-yellow-500': currentSlide === idx,
              }
            )}
            aria-current={currentSlide === idx}
            aria-label={`Slide ${idx + 1}`}
            onClick={() => setCurrentSlide(idx)}
          />
        ))}
      </div>

      {/* <!-- Slider controls --> */}
      <CarouselControl onClick={prevSlide} action='prev' ref={prevButtonRef} />
      <CarouselControl onClick={nextSlide} action='next' ref={nextButtonRef} />
    </div>
  );
};

export default Carousel;
