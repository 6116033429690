import { CheckIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { memo } from 'react';

import { AssessmentFormSectionStatus } from './hooks/types';

interface Props {
  status: AssessmentFormSectionStatus;
}

const AssessmentFormNavigationIcon = memo(({ status }: Props) => {
  return (
    <span
      className={clsx(
        'relative mx-auto h-5 w-5 rounded-full',
        status === 'not-started'
          ? 'bg-gray-300'
          : status === 'incomplete'
            ? 'bg-orange-500'
            : 'bg-yellow-500'
      )}
    >
      <CheckIcon
        aria-hidden='true'
        className='absolute left-1/2 top-1/2 h-3 w-3 -translate-x-1/2 -translate-y-1/2 text-gray-900'
      />
    </span>
  );
});

AssessmentFormNavigationIcon.displayName = 'AssessmentFormNavigationIcon';

export default AssessmentFormNavigationIcon;
