import { forwardRef, InputHTMLAttributes, useEffect, useState } from 'react';

import Checkbox from 'src/components/Checkbox';
import CollapsibleCard from 'src/components/CollapsibleCard';
import FileUpload from 'src/components/FileUpload';
import ImageThumbnail from 'src/components/ImageThumbnail';
import Text from 'src/components/Typography';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  ['data-id']: string;
  label: string;
  description: string;
  onFiles: (files: File[]) => Promise<void>;
  onRemoveFile: (idx: number) => Promise<void>;
  files: string[];
  currentValue?: string;
  onValueChange?: (value?: 'not-applicable' | 'no-access') => void;
}

const AssessmentFormMultiImageUploadQuestion = forwardRef<
  HTMLInputElement,
  Props
>(
  (
    {
      description,
      label,
      onFiles,
      files,
      onRemoveFile,
      onValueChange,
      currentValue,
      'data-id': dataId,
      ...props
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _ref
  ) => {
    const [notApplicable, setNotApplicable] = useState(
      currentValue === 'not-applicable'
    );
    const [noAccess, setNoAccess] = useState(currentValue === 'no-access');

    const disableCheckboxes = files.length > 0;

    useEffect(() => {
      setNotApplicable(currentValue === 'not-applicable');
      setNoAccess(currentValue === 'no-access');
    }, [currentValue]);

    return (
      <CollapsibleCard
        heading={
          <div className='flex flex-grow justify-between'>
            <Text as='label' className='flex-grow font-bold text-gray-900'>
              {label}
            </Text>
            <span className='min-w-9 rounded bg-orange-500 px-3 text-center'>
              <Text as='label' size='sm' className='font-bold text-white'>
                {files.length}
              </Text>
            </span>
          </div>
        }
      >
        <Text as='label' size='sm'>
          {description}
        </Text>

        <FileUpload
          key={label}
          onFiles={onFiles}
          data-id={`${dataId}-file-upload`}
          {...props}
        />

        <div className='flex gap-2'>
          {files.map((file, idx) => (
            <ImageThumbnail
              key={file}
              url={file}
              disabled={props.disabled}
              onRemoveFile={() => {
                void onRemoveFile(idx);
              }}
            />
          ))}
        </div>

        <div className='flex flex-col gap-5 rounded-lg bg-gray-200 p-4'>
          <Checkbox
            label="This doesn't apply"
            data-id={`${dataId}-not-applicable`}
            name='not-applicable'
            checked={notApplicable}
            disabled={disableCheckboxes}
            onChange={(e) => {
              setNotApplicable(e.target.checked);
              onValueChange?.(e.target.checked ? 'not-applicable' : undefined);
            }}
          />
          <Checkbox
            label="I can't access this"
            data-id={`${dataId}-no-access`}
            name='no-access'
            checked={noAccess}
            disabled={disableCheckboxes}
            onChange={(e) => {
              setNoAccess(e.target.checked);
              onValueChange?.(e.target.checked ? 'no-access' : undefined);
            }}
          />
        </div>
      </CollapsibleCard>
    );
  }
);

AssessmentFormMultiImageUploadQuestion.displayName =
  'AssessmentFormMultiImageUploadQuestion';

export default AssessmentFormMultiImageUploadQuestion;
