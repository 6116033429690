import {
  BoltIcon,
  ClockIcon,
  DevicePhoneMobileIcon,
  DocumentIcon,
  ViewfinderCircleIcon,
} from '@heroicons/react/24/outline';
import React, { createRef, useState } from 'react';

import AssessmentCover from 'src/assets/assessment-cover.jpeg';
import MaivenLogo from 'src/assets/maiven.svg';
import Wave from 'src/assets/wave.jpeg';
import Button from 'src/components/Button';
import Carousel from 'src/components/Carousel';
import AssessmentLeaveModal from 'src/pages/Assessment/components/AssessmentLeaveModal';
import AssessmentSliderItem from 'src/pages/Assessment/components/AssessmentSliderItem';

interface Props {
  onBegin: () => void;
}

const slidersCount = 4;

const AssessmentIntroduction: React.FC<Props> = ({ onBegin }) => {
  const [canContinue, setCanContinue] = useState(false);
  const prevButtonRef = createRef<HTMLButtonElement>();
  const nextButtonRef = createRef<HTMLButtonElement>();

  return (
    <div
      style={{ backgroundImage: `url('${AssessmentCover}')` }}
      className={`relative min-h-dvh bg-cover bg-center`}
    >
      <div className='absolute flex h-full w-full justify-center bg-black bg-opacity-50 text-white'>
        <div className='flex h-full flex-col justify-between gap-3 py-6 text-center md:w-1/2 md:justify-center md:px-0 md:py-0'>
          <Carousel
            nextButtonRef={nextButtonRef}
            prevButtonRef={prevButtonRef}
            containerClassName='flex-grow md:flex-grow-0 flex flex-col justify-center'
            reportSeenCount={(count) => {
              if (count === slidersCount) {
                setCanContinue(true);
              }
            }}
          >
            <AssessmentSliderItem
              icon={
                <div
                  className='relative mx-auto h-24 w-24 rounded-xl bg-cover bg-center p-4'
                  style={{ backgroundImage: `url('${Wave}')` }}
                >
                  <img src={MaivenLogo} alt='Maiven Logo' />
                </div>
              }
              title="Let's start your home energy assessment"
              description='Before you get started, here are a few things to have ready'
            />
            <AssessmentSliderItem
              icon={<ClockIcon className='mx-auto h-24 w-24 text-yellow-500' />}
              title='The assessment takes about 30 minutes to complete'
              description='The best part? You can do the whole thing in your pajamas.'
            />
            <AssessmentSliderItem
              icon={
                <div className='relative mx-auto h-24 w-24'>
                  <DocumentIcon className='text-yellow-500' />
                  <BoltIcon className='absolute left-1/2 top-[62%] h-9 w-9 -translate-x-1/2 -translate-y-1/2 transform text-yellow-500' />
                </div>
              }
              title='Have your utility bills or online account login credentials ready.'
              description="For the most accurate Maiven results, allow us to sync with your online utility account&mdash;you'll just need your username and password."
            />
            <AssessmentSliderItem
              icon={
                <div className='relative mx-auto h-24 w-24'>
                  <DevicePhoneMobileIcon className='text-yellow-500' />
                  <ViewfinderCircleIcon className='absolute left-1/2 top-1/2 h-9 w-9 -translate-x-1/2 -translate-y-1/2 transform text-yellow-500' />
                </div>
              }
              title="You'll need a smartphone and permission to use your camera."
              description="If you begin on your laptop or desktop, you'll need to switch to your phone for the photo portion."
            />
          </Carousel>
          <div className='flex flex-col gap-2 px-10'>
            <Button
              buttonStyle='light'
              onClick={() => {
                if (canContinue) {
                  onBegin();
                }

                nextButtonRef.current?.click();
              }}
            >
              {canContinue ? 'Begin' : 'Next'}
            </Button>
            {!canContinue ? (
              <Button
                buttonType='link'
                inverted
                onClick={() => {
                  prevButtonRef.current?.click();
                }}
              >
                Back
              </Button>
            ) : (
              <AssessmentLeaveModal
                triggerButtonProps={{
                  inverted: true,
                  buttonType: 'link',
                }}
              >
                Sign out
              </AssessmentLeaveModal>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssessmentIntroduction;
