import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useApiClient } from 'src/api/hooks/useApiClient';
import { AssessmentDetails, AssessmentResultType } from 'src/api/types';

interface PatchUserRequestParams {
  coreField: string;
  userId: string;
  firstName?: string;
  lastName?: string;
  avatarPath?: string;
  overrideNewValue?: string;
}

export const usePatchUser = () => {
  const { getClient } = useApiClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (requestParams: PatchUserRequestParams) => {
      const client = await getClient();

      await client.patch(`/users/${requestParams.userId}`, {
        firstName: requestParams.firstName,
        lastName: requestParams.lastName,
        avatarPath: requestParams.avatarPath,
      });
    },
    onSuccess: (_, variables) => {
      queryClient.setQueryData(
        ['assessment-details'],
        (old?: AssessmentDetails): AssessmentDetails | undefined => {
          return old
            ? {
                ...old,
                results: {
                  ...old.results,
                  [variables.coreField]: {
                    id:
                      old.results[variables.coreField]?.id ??
                      `new-response-${Date.now()}`,
                    value:
                      variables.overrideNewValue ??
                      variables.firstName ??
                      variables.lastName ??
                      variables.avatarPath ??
                      '',
                    assessmentQuestionId: variables.coreField,
                    type: AssessmentResultType.USER_PROVIDED,
                    values: [],
                  },
                },
              }
            : old;
        }
      );
    },
  });
};
