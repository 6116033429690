import { ChevronRightIcon } from '@heroicons/react/24/solid';
import React from 'react';
import { useSearchParams } from 'react-router-dom';

import CollapsibleCard from 'src/components/CollapsibleCard';
import Text from 'src/components/Typography';
import AssessmentFormNavigationIcon from 'src/pages/Assessment/components/AssessmentForm/AssessmentFormNavigationIcon';
import {
  AssessmentFormSection,
  AssessmentFormSectionContent,
} from 'src/pages/Assessment/components/AssessmentForm/hooks/types';

interface Props {
  sectionNames: AssessmentFormSection[];
  contents: Record<string, AssessmentFormSectionContent>;
}

const AssessmentReviewAndSubmit: React.FC<Props> = ({
  contents,
  sectionNames: _sectionNames,
}) => {
  const [, setSearchParams] = useSearchParams();
  const sectionNames = _sectionNames.filter(
    (section) => section.id !== 'summary'
  );

  return (
    <div className='min-h-1/4 flex flex-col gap-2 rounded-2xl bg-gray-50 p-4 sm:mb-5 sm:w-1/2 sm:self-center'>
      <Text className='font-bold'>Assessment Recap</Text>
      {sectionNames.map((section) => {
        const missingItems = section.totalCount - section.answered;

        return (
          <CollapsibleCard
            key={section.id}
            heading={
              <div className='flex flex-grow flex-col justify-between gap-1'>
                <div className='flex items-center gap-2'>
                  <AssessmentFormNavigationIcon status={section.status} />
                  <Text as='label' className='flex-grow font-bold'>
                    {section.id}
                  </Text>
                </div>
                <Text size='xs' className='flex-grow pl-7'>
                  {!section.answered
                    ? 'All items missing'
                    : missingItems > 0
                      ? `${missingItems} missing item${missingItems > 1 ? 's' : ''}`
                      : 'Complete'}
                </Text>
              </div>
            }
          >
            {contents[section.id].subsections.map((subsection) => (
              <div
                key={subsection.header}
                className='flex flex-grow cursor-pointer gap-2 text-gray-900 hover:text-gray-400'
                onClick={() => {
                  setSearchParams({
                    currentSection: section.id,
                    currentSubsection: subsection.header,
                  });
                }}
              >
                <Text size='sm' className='flex-grow font-bold'>
                  {subsection.header}
                </Text>
                <span className='h-5 w-5'>
                  <ChevronRightIcon />
                </span>
              </div>
            ))}
          </CollapsibleCard>
        );
      })}
    </div>
  );
};

export default AssessmentReviewAndSubmit;
