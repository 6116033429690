import Button from '.';

const ButtonTesting = () => {
  return (
    <div
      className='flex flex-grow flex-col gap-2 bg-cover p-16'
      style={{ backgroundColor: 'cyan' }}
    >
      Dark
      <div
        style={{ backgroundColor: 'white' }}
        className='flex flex-row gap-2 p-2 text-center'
      >
        <Button buttonStyle='dark' buttonType='normal'>
          Normal
        </Button>
        <Button buttonStyle='dark' buttonType='outline'>
          Outline
        </Button>
        <Button buttonStyle='dark' buttonType='pill'>
          Pill
        </Button>
        <Button buttonStyle='dark' buttonType='link'>
          Link
        </Button>
        <Button disabled buttonStyle='dark'>
          Disabled
        </Button>
      </div>
      <div className='flex flex-row gap-2 bg-gray-900 p-2 text-center'>
        <Button inverted buttonStyle='dark' buttonType='normal'>
          Normal
        </Button>
        <Button inverted buttonStyle='dark' buttonType='outline'>
          Outline
        </Button>
        <Button inverted buttonStyle='dark' buttonType='pill'>
          Pill
        </Button>
        <Button inverted buttonStyle='dark' buttonType='link'>
          Link
        </Button>
        <Button disabled inverted buttonStyle='dark'>
          Disabled
        </Button>
      </div>
      Light
      <div
        style={{ backgroundColor: 'white' }}
        className='flex flex-row gap-2 p-2 text-center'
      >
        <Button buttonStyle='light' buttonType='normal'>
          Normal
        </Button>
        <Button buttonStyle='light' buttonType='outline'>
          Outline
        </Button>
        <Button buttonStyle='light' buttonType='pill'>
          Pill
        </Button>
        <Button buttonStyle='light' buttonType='link'>
          Link
        </Button>
        <Button disabled buttonStyle='light'>
          Disabled
        </Button>
      </div>
      <div className='flex flex-row gap-2 bg-gray-900 p-2 text-center'>
        <Button inverted buttonStyle='light' buttonType='normal'>
          Normal
        </Button>
        <Button inverted buttonStyle='light' buttonType='outline'>
          Outline
        </Button>
        <Button inverted buttonStyle='light' buttonType='pill'>
          Pill
        </Button>
        <Button inverted buttonStyle='light' buttonType='link'>
          Link
        </Button>
        <Button disabled inverted buttonStyle='light'>
          Disabled
        </Button>
      </div>
      Accent1
      <div
        style={{ backgroundColor: 'white' }}
        className='flex flex-row gap-2 p-2 text-center'
      >
        <Button buttonStyle='accent-1' buttonType='normal'>
          Normal
        </Button>
        <Button buttonStyle='accent-1' buttonType='outline'>
          Outline
        </Button>
        <Button buttonStyle='accent-1' buttonType='pill'>
          Pill
        </Button>
        <Button buttonStyle='accent-1' buttonType='link'>
          Link
        </Button>
        <Button disabled buttonStyle='accent-1'>
          Disabled
        </Button>
      </div>
      <div className='flex flex-row gap-2 bg-gray-900 p-2 text-center'>
        <Button inverted buttonStyle='accent-1' buttonType='normal'>
          Normal
        </Button>
        <Button inverted buttonStyle='accent-1' buttonType='outline'>
          Outline
        </Button>
        <Button inverted buttonStyle='accent-1' buttonType='pill'>
          Pill
        </Button>
        <Button inverted buttonStyle='accent-1' buttonType='link'>
          Link
        </Button>
        <Button disabled inverted buttonStyle='accent-1'>
          Disabled
        </Button>
      </div>
    </div>
  );
};

export default ButtonTesting;
