import { Auth0Provider } from '@auth0/auth0-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { BrowserRouter as Router } from 'react-router-dom';

import { ErrorBoundary } from './components/ErrorBoundary';
import AuthProvider from './contexts/AuthContext';
import Pages from './pages';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const REDIRECT_URL = window.location.origin;
const audienceDomain =
  import.meta.env.VITE_AUTH0_AUDIENCE ?? import.meta.env.VITE_BACKEND_DOMAIN;

const App = () => {
  return (
    <Auth0Provider
      domain={import.meta.env.VITE_AUTH0_DOMAIN}
      clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: REDIRECT_URL,
        audience: `https://${audienceDomain}`,
      }}
      useCookiesForTransactions
    >
      <QueryClientProvider client={queryClient}>
        <Router>
          <ErrorBoundary>
            <AuthProvider>
              <Pages />
            </AuthProvider>
          </ErrorBoundary>
        </Router>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Auth0Provider>
  );
};

export default App;
