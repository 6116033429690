import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import { useAuthContext } from 'src/contexts/AuthContext';

const PrivateRoute: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { isAuthenticated, handleLogout } = useAuthContext();

  useEffect(() => {
    if (!isAuthenticated) {
      handleLogout();
    }
  }, [handleLogout, isAuthenticated]);

  if (!isAuthenticated) return <Navigate to='/login' />;

  return <>{children}</>;
};

export default PrivateRoute;
