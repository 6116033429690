import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from '@headlessui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import React, { Fragment, useState } from 'react';

import Button, { ButtonProps } from 'src/components/Button';
import Text from 'src/components/Typography';

interface ModalProps {
  title: string;
  primaryActionLabel: string;
  primaryActionProps: ButtonProps;
  secondaryActionLabel?: string;
  secondaryActionProps?: ButtonProps;
  triggerButtonContent: JSX.Element;
  triggerButtonProps?: ButtonProps;
  action: 'warning';
}

const Modal: React.FC<React.PropsWithChildren<ModalProps>> = ({
  action,
  children,
  primaryActionLabel,
  primaryActionProps,
  secondaryActionLabel,
  secondaryActionProps,
  title,
  triggerButtonContent,
  triggerButtonProps,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      {triggerButtonProps ? (
        <Button
          {...triggerButtonProps}
          onClick={(e) => {
            try {
              triggerButtonProps.onClick?.(e);
            } catch {
              // no-op
            }

            setIsOpen(true);
          }}
        >
          {triggerButtonContent}
        </Button>
      ) : (
        <button
          onClick={() => {
            setIsOpen(true);
          }}
        >
          {triggerButtonContent}
        </button>
      )}
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        className='relative z-50'
      >
        {/* The backdrop, rendered as a fixed sibling to the panel container */}
        <DialogBackdrop className='fixed inset-0 bg-gray-900/40' />

        {/* Full-screen container to center the panel */}
        <div className='fixed inset-0 flex w-screen items-center justify-center p-4'>
          {/* The actual dialog panel  */}
          <DialogPanel className='flex max-w-lg flex-grow gap-4 rounded-lg bg-white px-2 py-10 lg:px-12'>
            {/* Action */}
            <div>
              <span
                className={clsx('relative block h-11 w-11 rounded-full', {
                  'bg-yellow-100': action === 'warning',
                })}
              >
                <ExclamationTriangleIcon
                  className={clsx(
                    'absolute left-1/2 top-1/2 h-5 w-5 -translate-x-1/2 -translate-y-1/2',
                    {
                      'text-yellow-500': action === 'warning',
                    }
                  )}
                />
              </span>
            </div>

            {/* Content */}
            <div className='flex flex-grow flex-col gap-8'>
              {/* Body */}
              <div className='flex flex-col gap-4'>
                <DialogTitle as={Fragment}>
                  <Text size='xl' className='font-bold'>
                    {title}
                  </Text>
                </DialogTitle>
                {children}
              </div>
              {/* Actions */}
              <div className='flex gap-4'>
                <Button
                  buttonStyle='accent-1'
                  {...primaryActionProps}
                  onClick={(e) => {
                    try {
                      primaryActionProps.onClick?.(e);
                    } catch {
                      // no-op
                    }

                    setIsOpen(false);
                  }}
                >
                  {primaryActionLabel}
                </Button>
                {secondaryActionLabel && (
                  <Button
                    buttonType='outline'
                    buttonStyle='dark'
                    autoFocus
                    {...secondaryActionProps}
                    onClick={(e) => {
                      try {
                        secondaryActionProps?.onClick?.(e);
                      } catch {
                        // no-op
                      }

                      setIsOpen(false);
                    }}
                  >
                    {secondaryActionLabel}
                  </Button>
                )}
              </div>
            </div>
          </DialogPanel>
        </div>
      </Dialog>
    </>
  );
};

export default Modal;
